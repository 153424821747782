import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { refreshUser } from "src/modules/account/service/AuthAction";
import { decodeAllJWT } from "src/utils/decodeJwtCurrentUser";
const GuestGuard = ({ children, refreshUser }) => {
  const { isAuthenticated, rolesUser } = useSelector(
    (state) => state.authentication
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    if (
      localStorage.getItem("refresh_token") &&
      localStorage.getItem("access_token") && (isAuthenticated||localStorage.getItem("isAuthenticated"))
    ) {

      if (  rolesUser && rolesUser.includes("ROLE_ADMIN")) {
        if (currentPath === "/") {
          navigate("/admin/dashboard", { replace: true });
        }
        navigate("/admin/dashboard", { replace: true });
      }

      // CLIENT PART
      else if (
        isAuthenticated &&
        rolesUser &&
        rolesUser.includes("ROLE_HORECA")
      ) {

        if (currentPath === "/") {
          navigate("/horeca/dashboard", { replace: true });
        }
        navigate("/horeca/dashboard", { replace: true });
      }

      // ACCOUNTANT PART
      else if (
        isAuthenticated &&
        rolesUser &&
        rolesUser.includes("ROLE_ACCOUNTANT")
      ) {
        if (currentPath === "/") {
          navigate("/accountant/dashboard", { replace: true });
        }

        navigate("/accountant/dashboard", { replace: true });
      }

      refreshUser();

    }

    else if (currentPath === "/auth/forgot-password/confirmation") {
    

      const previousPath = localStorage.getItem("previousPath");

      if (previousPath !== "/auth/forgot-password") {
        navigate("/auth/login", { replace: true });
      }
    }

    else  if (currentPath === "/") {
      navigate("/", { replace: true });

    }
    
  }, [isAuthenticated, navigate, refreshUser, rolesUser]);

  return children;
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

export default connect(mapStateToProps, { refreshUser })(GuestGuard);
