import {
  IconPoint,
  IconDashboard,
  IconUserCircle,
  IconShoppingBag,
  IconFileInvoice,
  IconTicket,
  IconCalculator,
  IconSettings,
  IconCreditCard,
  IconBrandUbuntu,
} from "@tabler/icons";

import { uniqueId } from "lodash";

const Menuitems = [
  {
    navlabel: true,
    subheader: "Home",
  },

  {
    id: uniqueId(),
    title: "Dashboard",
    icon: IconDashboard,
    href: "/horeca/dashboard",
  },
  {
    navlabel: true,
    subheader: "GESTIONE",
  },
  // {
  //   id: uniqueId(),
  //   title: "Isa Code ",
  //   icon: IconHash,
  //   chipColor: "secondary",
  //   href: "/isacode",
  // },
  // {

  {
    id: uniqueId(),
    title: "Prodotti",
    icon: IconShoppingBag,
    chipColor: "secondary",
    href: "/horeca/products",
  },
  {
    id: uniqueId(),
    title: "Fornitori",
    icon: IconUserCircle,
    href: "/horeca/suppliers",
  },
  {
    id: uniqueId(),
    title: "Fatture",
    icon: IconFileInvoice,
    href: "/invoices",
    children: [
      {
        id: uniqueId(),
        title: "Caricamento fatture",
        icon: IconPoint,
        href: "/horeca/invoice/upload",
      },
      {
        id: uniqueId(),
        title: "Elenco ",
        icon: IconPoint,
        href: "/horeca/invoice/list",
      },
    ],
  },

  {
    id: uniqueId(),
    title: "Commercialista",
    icon: IconCalculator,
    href: "/horeca/accountant/list",
  },
  {
    id: uniqueId(),
    title: "Assistenza",
    icon: IconTicket,
    href: "/tickets",
    children: [
      {
        id: uniqueId(),
        title: "Crea ticket",
        icon: IconPoint,
        href: "/horeca/ticket/create",
      },
      {
        id: uniqueId(),
        title: "Elenco richieste",
        icon: IconPoint,
        href: "/horeca/ticket/list",
      },
    ],
  },

  {
    id: uniqueId(),
    title: "Cronologia Fatture",
    icon: IconBrandUbuntu,
    href: "/horeca/system/operation",
  },

  {
    id: uniqueId(),
    title: "Piano Attuale",
    icon: IconCreditCard,
    href: "/horeca/subscription/all",
  },

  {
    id: uniqueId(),
    title: "Impostazioni",
    icon: IconSettings,
    href: "/horeca/personalData/update",
  },
];

export default Menuitems;
