import {
  IconPoint,
  IconDashboard,
  IconHash,
  IconUserCircle,
  IconShoppingBag,
  IconFileInvoice,
  IconTicket,
  IconBuildingWarehouse,
  IconSpeakerphone,
  IconSettings,
  IconCreditCard,
} from "@tabler/icons";

import { uniqueId } from "lodash";

const MenuItemsAccountant = [
  {
    navlabel: true,
    subheader: "Home",
    withoutNeedClient: true,
    changeUrl: false,
  },

  {
    id: uniqueId(),
    title: "Dashboard",
    icon: IconDashboard,
    href: "/accountant/dashboard",
    withoutNeedClient: true,
    changeUrl: false,
  },
  {
    navlabel: true,
    subheader: "Gestione",
    withoutNeedClient: true,
    changeUrl: false,
  },

  {
    id: uniqueId(),
    title: "Clienti ",
    icon: IconUserCircle,
    chipColor: "secondary",
    href: "/accountant/horeca/list",
    withoutNeedClient: true,
    children: [
      {
        id: uniqueId(),
        title: "Creare Cliente",
        icon: IconPoint,
        href: "/accountant/horeca/create-client",
      },
      {
        id: uniqueId(),
        title: "Elenco ",
        icon: IconPoint,
        href: "/accountant/horeca/list",
      },
      {
        id: uniqueId(),
        title: "Acces Key ",
        icon: IconPoint,
        href: "/accountant/horeca/confirm/acceskey",
      },
    ],
  },

  {
    id: uniqueId(),
    title: "Isa Code ",
    icon: IconHash,
    chipColor: "secondary",
    withoutNeedClient: false,
    href: "/accountant/isacode/list",
    changeUrl: true,
  },

  {
    id: uniqueId(),
    title: "Prodotti",
    icon: IconShoppingBag,
    chipColor: "secondary",
    withoutNeedClient: false,
    href: "/accountant/product/list",
    changeUrl: true,
  },

  {
    id: uniqueId(),
    title: "Fatture",
    icon: IconFileInvoice,
    href: "/invoices",
    withoutNeedClient: false,
    changeUrl: true,
    children: [
      {
        id: uniqueId(),
        title: "Caricamento fatture",
        icon: IconPoint,
        href: "/accountant/invoice/upload",
      },
      {
        id: uniqueId(),
        title: "Elenco ",
        icon: IconPoint,
        href: "/accountant/invoice/list",
      },
    ],
  },

  {
    id: uniqueId(),
    title: "Fornitori",
    icon: IconBuildingWarehouse,
    withoutNeedClient: false,
    href: "/accountant/supplier/list",
    changeUrl: true,
  },

  {
    id: uniqueId(),
    title: "Messaggi Generali",
    icon: IconSpeakerphone,
    chipColor: "secondary",
    withoutNeedClient: false,
    href: "/accountant/generalCommunication",
  },

  {
    id: uniqueId(),
    title: "Assistenza",
    icon: IconTicket,
    href: "/tickets",
    withoutNeedClient: true,
    changeUrl: true,

    children: [
      {
        id: uniqueId(),
        title: "Crea ticket",
        icon: IconPoint,
        href: "/accountant/ticketing/create",
      },
      {
        id: uniqueId(),
        title: "Elenco richieste",
        icon: IconPoint,
        href: "/accountant/ticketing/list",
      },
    ],
  },
  {
    id: uniqueId(),
    title: "Piano Attuale",
    withoutNeedClient: true,
    icon: IconCreditCard,
    href: "/accountant/subscription/all",
  },
  {
    id: uniqueId(),
    title: "Impostazioni",
    icon: IconSettings,
    withoutNeedClient: true,
    href: "/accountant/personalData/update",
  },
];

export default MenuItemsAccountant;
