import { GETPERSONALDATA, RESETPERSONDATA } from "src/modules/account/accountManagement/service/AccountManagementActionTypes";
const initialState = {
  personalData: null,
};

export default function AccountManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GETPERSONALDATA:
      return {
        ...state,
        personalData: action.payload.data,
      };

      case RESETPERSONDATA:

      return{
        ...state,
        personalData: null,
      }

    default: {
      return state;
    }
  }
}
