import {
  GETALLCATEGORY,
  SELECTEDCATEGORY,
  GETALLCATEGORYBYPARENT,
  RACINECATEGORY,
  GETALLCATEGORYANDSUBCATEGORY,
} from "src/modules/categorization/category/service/CategoryActionType";

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  categoryList: [],
  selectedCategory: {},
  racineCategory: {},
};

export default function CategoryReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLCATEGORY:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        categoryList: action.payload.data,
      };
    case GETALLCATEGORYBYPARENT:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        categoryList: action.payload.data,
      };
    case SELECTEDCATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case RACINECATEGORY:
      return {
        ...state,
        racineCategory: action.payload.data,
      };
    case GETALLCATEGORYANDSUBCATEGORY:
      return {
        ...state,
        categoryList: action.payload.data,
      };
    default: {
      return state;
    }
  }
}
