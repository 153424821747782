import {
  UPLOADINVOICE,
  INVOICE_LOADING,
  GETALLINVOICE,
  GETINVOICEBYUUID,
  GETFILTREDINVOICE,
} from "src/modules/invoice/service/InvoiceActionType";

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  invoiceList: [],
  invoiceDetails: {},
  isLoading: false,
};

export default function InvoiceReducer(state = initialState, action) {
  switch (action.type) {
    case UPLOADINVOICE:
      return {
        ...state,
        invoiceUplaod: action.payload.data,
      };
    case INVOICE_LOADING:
      return {
        ...state,
        isLoading: action.payload, // true to start loading, false to stop
      };
    case GETALLINVOICE:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        invoiceList: action.payload.data,
      };

    case GETFILTREDINVOICE:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        invoiceList: action.payload.data,
      };

    case GETINVOICEBYUUID:
      return {
        ...state,
        invoiceDetails: action.payload.data,
      };

    default: {
      return state;
    }
  }
}
