import {
  GETALLSYSTEMOPERATION,
  GETLASTSYSTEMOPERATION,
  RESETLASTSYSTEMOPERATION,
  RESETSYSTEMOPERATIONLIST,
} from "src/modules/systemOperation/service/SystemOperationActionTypes";

const initialState = {
  lastOperation: null,

  systemOperationList: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
};

export default function SystemOperationReducer(state = initialState, action) {
  switch (action.type) {
    case GETLASTSYSTEMOPERATION:
      return {
        ...state,
        lastOperation: action.payload,
      };

    case RESETLASTSYSTEMOPERATION:
      return {
        ...state,
        lastOperation: null,
      };

    case GETALLSYSTEMOPERATION:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        systemOperationList: action.payload.data,
      };

    case RESETSYSTEMOPERATIONLIST:
      return {
        ...state,
        currentPage: 0,
        totalPages: 0,
        totalElements: 0,
        systemOperationList: [],
      };

    default: {
      return state;
    }
  }
}
