import {
  GETALLPRODUCTS,
  GETALLPRODUCTSBYCATEGORYUSER,
  GETPURCHASEHISTORYPRODUCTS,
  GETPRODUCTYEARLYSPENDING,
  GETPRODUCTYEARLYSPENDINGBYPERIOD,
  GETPRODUCTYEARLYSPENDINGBYPERIODSEARCHED,
  RESETGETPRODUCTYEARLYSPENDINGBYPERIODSEARCHED,
  RESETSTATEPRODUCTS,
} from "src/modules/categorization/product/service/ProductActionType";

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  productsList: [],
  purchaseHistoryProduct: [],
  yearlySpendingProduct: [],
  yearlySpendingByPeriodProduct: {},
  yearlySpendingByPeriodProductSearched: null,
  searchedDate: null,
};

export default function ProductsReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLPRODUCTS:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        productsList: action.payload.data,
      };
    case GETALLPRODUCTSBYCATEGORYUSER:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        productsList: action.payload.data,
      };
    case GETPURCHASEHISTORYPRODUCTS:
      return {
        ...state,
        purchaseHistoryProduct: action.payload.data,
      };

    case GETPRODUCTYEARLYSPENDING:
      return {
        ...state,
        yearlySpendingProduct: action.payload.data,
      };

    case GETPRODUCTYEARLYSPENDINGBYPERIOD:
      return {
        ...state,
        yearlySpendingByPeriodProduct: action.payload.data,
      };
    case GETPRODUCTYEARLYSPENDINGBYPERIODSEARCHED:
      return {
        ...state,
        yearlySpendingByPeriodProductSearched: action.payload.data,
        searchedDate: action.payload.searchedDate,
      };
    case RESETGETPRODUCTYEARLYSPENDINGBYPERIODSEARCHED:
      return {
        ...state,
        yearlySpendingByPeriodProductSearched: null,
        searchedDate: null,
      };
    case RESETSTATEPRODUCTS:
      return {
        ...state,
        currentPage: 0,
        totalPages: 0,
        totalElements: 0,
        productsList: [],
      };
    default: {
      return state;
    }
  }
}
