import React, { useEffect, useState } from "react";
import Menuitems from "./MenuItems";
import { useLocation } from "react-router";
import { Box, List, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch, connect } from "react-redux";
import { toggleMobileSidebar } from "src/store/customizer/CustomizerSlice";
import NavItem from "./NavItem";
import NavCollapse from "./NavCollapse";
import NavGroup from "./NavGroup/NavGroup";
import MenuitemsAdmin from "./MenuItemsAdmin";
import MenuItemsAccountant from "./MenuItemsAccountant";

const SidebarItems = ({ admin, accountant }) => {
  const { pathname } = useLocation();
  const [menuItemsToDisplay, setMenuItemsToDisplay] = useState([]);
  const pathDirect = pathname;
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf("/"));
  const customizer = useSelector((state) => state.customizer);

  const { selectedClient, isSelectedCLient } = useSelector(
    (state) => state.accountantReducer
  );
  const dispatch = useDispatch();

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const hideMenu = lgUp
    ? customizer.isCollapse && !customizer.isSidebarHover
    : "";

  const menuItems = admin
    ? MenuitemsAdmin
    : accountant
    ? MenuItemsAccountant
    : Menuitems;

  useEffect(() => {
    if (accountant && !isSelectedCLient) {
      // Filter out menu items with withoutNeedClient as true or if selectedClient is true
      const filteredMenuItems = menuItems.filter(
        (item) => item.withoutNeedClient
      );

      setMenuItemsToDisplay(filteredMenuItems);
    } else if (accountant && isSelectedCLient) {
      // Override withoutNeedClient to true for all menu items
      const modifiedMenuItems = menuItems.map((item) => {
        const updatedItem = {
          ...item,
          withoutNeedClient: true,
          href: `${item.href}?costumerUuid=${
            selectedClient.uuid
          }&costumerName=${encodeURIComponent(selectedClient.companyName)}`,
        };
        // If item has children, update their href as well
        if (item.children) {
          updatedItem.children = item.children.map((child) => ({
            ...child,
            href: `${child.href}?costumerUuid=${
              selectedClient.uuid
            }&costumerName=${encodeURIComponent(selectedClient.companyName)}`,
          }));
        }
        return updatedItem;
      });
      setMenuItemsToDisplay(modifiedMenuItems);
    } else {
      setMenuItemsToDisplay(menuItems);
    }
  }, [isSelectedCLient,selectedClient]); // Listen for changes in selectedClient

  return (
    <Box sx={{ px: 3 }}>
      <List sx={{ pt: 0 }} className="sidebarNav">
        {menuItemsToDisplay.map((item, index) => {
          // {/********SubHeader**********/}
          if (item.subheader) {
            return (
              <NavGroup item={item} hideMenu={hideMenu} key={item.subheader} />
            );

            // {/********If Sub Menu**********/}
            /* eslint no-else-return: "off" */
          } else if (item.children) {
            return (
              <NavCollapse
                menu={item}
                pathDirect={pathDirect}
                hideMenu={hideMenu}
                pathWithoutLastPart={pathWithoutLastPart}
                level={1}
                key={item.id}
                onClick={() => dispatch(toggleMobileSidebar())}
              />
            );

            // {/********If Sub No Menu**********/}
          } else {
            return (
              <NavItem
                item={item}
                key={item.id}
                pathDirect={pathDirect}
                hideMenu={hideMenu}
                onClick={() => dispatch(toggleMobileSidebar())}
              />
            );
          }
        })}
      </List>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    accountantReducer: state.accountantReducer,
  };
};
export default connect(mapStateToProps, {})(SidebarItems);
