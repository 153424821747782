export const GETALLACCOUNTANT = "GET_ALL_ACCOUNTANT";
export const SENDREQUESTTOACCOUNTANT = "SEND_REQUEST_TO_ACCOUNTANT";
export const GETALLCLIENT = "GET_ALL_CLIENT";
export const GETALLINVOICECOSTUMER = "GET_ALL_INVOICE_BY_CONSTUMER";
export const GETINVOICEBYUUIDANDCOSTUMERUUID =
  "GET_INVOICE_BY_UUID_AND_COSTUMER_UUID";
export const GETALLSUPPLIERBYCOSTUMER = "GET_ALL_SUPPLIER_BY_COSTUMER";
export const GETALLPRODUCTBYCUSTOMERUUID =
  "GET_ALL_PRODUCT_BY_CUSTOMER_BY_UUID";

export const GETALLISACODEBYCUSTOMERUUID = "GET_ALL_ISA_CODE_BY_CUSTOMER_UUID";
export const GETPURCHASEHISTORYBYPERIODANDCUSTOMERUUIDANDPRODUCT =
  "GET_PURCHASE_HISTORY_BY_PERIOD_BY_CUSTOMER_BY_PRODUCTS";

export const SENDNOTIFICATIONTOCUSTOMER = "SEND_NOTIFICATION_TO_CUSTOMER";
export const GETACCOUNTANTMETRICSFORACCOUTANT =
  "GET_ACCOUNTANT_METRICS_FOR_ACCOUTANT";
export const ACCESSKEYRECEIVED = "ACCESS_KEY_RECEIVED";
export const CREATENEWCLIENT = "CREATE_NEW_CLIENT";
export const CREATENEWCLIENTFAILURE = "CREATE_NEW_CLIENT_FAILURE";

export const SELECTEDCLIENTFORACCOUNTANT = "SELECTED_CLIENT_FOR_ACCOUNTANT";
