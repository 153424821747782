import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import axios from "axios";
import { environment } from "src/environement/environment";
import { refreshUser } from "src/modules/account/service/AuthAction";
import { AUTHSUCCESS } from "src/modules/account/service/AuthTypes";
import { clearCookies } from "src/utils/cookiesTools";
import swal from "sweetalert";
import { formatToCapitalize } from "src/utils/formatRowValue";

const AuthGuard = ({ children, refreshUser }) => {
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const refreshToken = async () => {
      const currentPath = location.pathname;

      try {
        const response = await axios.post(
          `${environment.baseUrl}/userRegistration/refresh`,
          {}
        );

        // Extract the refresh_token from the response

        // Set the refresh_token in the headers for subsequent requests
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("refresh_token")}`;

        if (response.status === 201 && response.status !== undefined) {
          const roles = response.data.roles;

          // IF THE CURRENT USER  IS ADMIN
          if (roles.includes("ROLE_ADMIN")) {
            navigate("/admin/dashboard");
          } else if (roles.includes("ROLE_ACCOUNTANT")) {
            navigate("/accountant/dashboard");
          } else if (roles.includes("ROLE_HORECA")) {
            dispatch({ type: AUTHSUCCESS, payload: { roles: roles } });
          } else {
            navigate("/auth/404", { replace: true });
          }
        }
      } catch (error) {
        if (
          error.response.status !== undefined &&
          error.response.status === 402
        ) {

          swal({
            title: "Richiesta non riuscita",
            text: ` ${formatToCapitalize(error.response.data.message)} `,
            icon: "warning",
            button: "OK",
            dangerMode: true,
          }).then(() => {
            // navigate("/horeca/subscription/all"); // Replace "/dashboard" with the desired route
          });
          clearCookies();

          navigate("/auth/login", { replace: true });
        } else {
          clearCookies();

          navigate("/auth/login", { replace: true });
        }
      }
    };

    refreshToken();
  }, [navigate]);

  useEffect(() => {
    refreshUser();
    if (isAuthenticated && window.location.pathname === "/auth/login") {
      navigate("/horeca/dashboard", { replace: true });
    }
  }, [isAuthenticated, navigate, refreshUser]);

  return children;
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

export default connect(mapStateToProps, { refreshUser })(AuthGuard);
