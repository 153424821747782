export const GETSTATISTIQUEPRODUCTS = "GET_STATISTIQUE_PRODUCTS";
export const GETREVENUEBYCATEGORY = "GET_REVENUE_BY_CATEGORY";
export const GETMOSTPOPULARPRODUCTS = "GET_MOST_POPULAR_PRODUCTS";
export const GETTOPINVOICEBYTOTALAMOUNT = "GET_TOP_INVOICE_BY_TOTAL_AMOUNT";
export const GETTOTALREVENUEPERYEAR = "GET_TOTAL_REVENUE_PER_YEAR";
export const GETINVOICESTATISTIQUE = "GET_INVOICE_STATISTIQUE";
export const GETUSERSENGAGEMENT = "GET_USERS_ENGAGEMENTS";
export const GETCOSTUMERSERVICE = "GET_COSTUMER_SERVICE";
export const GETFINANCIALMETRICS = "GET_FINANCIAL_METRICS";

export const GETDAILYINVOICE = "GET_DAILY_INVOICE";
export const GETWEEKLYINVOICE = "GET_WEEKLY_INVOICE";
export const GETMONTHLYINVOICE = "GET_MONTHLY_INVOICE";
export const GETYEARLYINVOICE = "GET_YEARLY_INVOICE";

export const GETALLSUPPLIERBYADMIN ="GET_ALL_SUPPLIER_BY_ADMIN";
export const GETALLPRODUCTBYADMIN ="GET_ALL_PRODUCT_BY_ADMIN";



export const GETINVOICEPERPERIODEPERSUPPLIER = "GET_INOIVCE_PER_PERIOD_PER_SUPPLIER";
export const GETINVOICEPERPERIODEPERCOSTUMER = "GET_INOIVCE_PER_PERIOD_PER_COSTUMER";
export const GETINVOICEPERPERIODEPERPRODUCT = "GET_INOIVCE_PER_PERIOD_PER_PRODUCT";
export const GETINVOICEPERPERIODE = "GET_INOIVCE_PER_PERIOD";
export const GETTRACKINGPRODUCT = "GET_TRACKING_PRODUCT";

export const GETACCOUNTANTMETRICS = "GET_ACCOUNTANT_METRICS";



