import { GETACCOUNTANTMETRICSFORACCOUTANT } from "src/modules/accountant/service/AccountantActionType";

const initialState = {
  accountantMetricsDashboard: {},
};

export default function AccountantDashboardReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GETACCOUNTANTMETRICSFORACCOUTANT:
      return {
        ...state,
        accountantMetricsDashboard: action.payload.data,
      };

    default: {
      return state;
    }
  }
}
