import {
  GETALLPRODUCTTOBECATEORIZED,
  GETALLCATEGORYPARENT,
  GETALLSUBCATEGORYBYPARENT,
  RESETESUBCATEGORY,
} from "src/modules/admin/product/service/ProductActionTypes";

const initialState = {
  // LIST OF COUPON
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  allProductNotCategorized: [],

  listCategoryParent: [],
  listSubCategoryByParent: [],
};

export default function AdminProductReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLPRODUCTTOBECATEORIZED:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        allProductNotCategorized: action.payload.data,
      };

    case GETALLCATEGORYPARENT:
      return {
        ...state,
        listCategoryParent: action.payload.data,
      };

    case GETALLSUBCATEGORYBYPARENT:
      return {
        ...state,
        listSubCategoryByParent: action.payload.data,
      };

      case RESETESUBCATEGORY : 
      return {
        ...state,
        listSubCategoryByParent: [],
      };

    default: {
      return state;
    }
  }
}
