export const environment = {
  // production: false,
  // baseUrl: "http://localhost:8080/api/v1",

  
  //8444
  production: false,
  baseUrl: "https://horecacode.it:8444/api/v1",

  // production: false,
  // baseUrl: "https://horecacode-57b2a6aa10e8.herokuapp.com/api/v1",
};

export const environmentDecrypt = {

  // production: false,
  // baseUrl: "http://localhost:3000",

  production: false,
  baseUrl: "https://horecacode.it:8445",
};
