import {
  GETALLTICKETBYADMIN,
  CLEARTICKETBYADMIN,
  GETANSWEREDTICKET,
} from "src/modules/admin/ticketing/service/TicketingActionTypes";
import {
  CLEARTICKETBYOWER,
  CREATE_TICKET,
  GETALLTICKETBYCURRENTUSER,
} from "src/modules/ticketing/service/TicketingActionTypes";

const initialState = {
  ticketAdded: {},

  // LIST TICKET
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  // FOR CLIENT
  ticketList: [],

  //FOR ADMIN
  ticketListAdmin: [],
  answeredTicket: null,
};

export default function TicketingReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_TICKET:
      return {
        ...state,
        ticketAdded: action.payload.data,
      };

    case GETALLTICKETBYCURRENTUSER:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        ticketList: action.payload.data,
      };

    case GETALLTICKETBYADMIN:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        ticketListAdmin: action.payload.data,
      };

    case GETANSWEREDTICKET:
      return {
        ...state,
        answeredTicket: action.payload.data,
      };

    case CLEARTICKETBYOWER:
      return {
        ...state,
        ticketList: [],
      };

    case CLEARTICKETBYADMIN:
      return {
        ...state,
        ticketListAdmin: [],
      };

    default: {
      return state;
    }
  }
}
