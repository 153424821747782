import {
  GETALLSUPPLIER,
  GETINVOICESBYSUPPLIER,
  GETPRODUCTSBYSUPPLIER,
  GETSPENDINGITEMSBYSUPPLIER,
} from "src/modules/supplier/service/SupplierActionType";

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  supplierList: [],

  // INVOICES BY SUPPLIER
  currentPageInvoiceBySupplier: 0,
  totalPagesInvoiceBySupplier: 0,
  totalElementsInvoiceBySupplier: 0,
  invoiceListBySupplier: [],

  // PRODUCT BY SUPPLIER
  currentPageProductBySupplier: 0,
  totalPagesProductBySupplier: 0,
  totalElementsProductBySupplier: 0,
  productListBySupplier: [],

  // DATA FOR CHARTS
  itemSpendingBySupplier: [],
  typeCharts: null,
};

export default function SupplierReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLSUPPLIER:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        supplierList: action.payload.data,
      };

    case GETINVOICESBYSUPPLIER:
      return {
        ...state,
        currentPageInvoiceBySupplier: action.payload.currentPage,
        totalPagesInvoiceBySupplier: action.payload.totalPages,
        totalElementsInvoiceBySupplier: action.payload.totalElements,
        invoiceListBySupplier: action.payload.data,
      };

    case GETPRODUCTSBYSUPPLIER:
      return {
        ...state,
        currentPageProductBySupplier: action.payload.currentPage,
        totalPagesProductBySupplier: action.payload.totalPages,
        totalElementsProductBySupplier: action.payload.totalElements,
        productListBySupplier: action.payload.data,
      };

    case GETSPENDINGITEMSBYSUPPLIER:
      return {
        ...state,
        itemSpendingBySupplier: action.payload.data,
        typeCharts: action.payload.typeCharts,
      };

    default: {
      return state;
    }
  }
}
