import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import axios from "axios";
import { environment } from "src/environement/environment";
import { refreshUser } from "src/modules/account/service/AuthAction";
import { AUTHSUCCESS } from "src/modules/account/service/AuthTypes";
import { clearCookies } from "src/utils/cookiesTools";
import swal from "sweetalert";
import { formatToCapitalize } from "src/utils/formatRowValue";

const AuthAccountantGuard = ({ children, refreshUser }) => {
  const { isAuthenticated } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const refreshToken = async () => {
      const currentPath = location.pathname;

      try {
        const response = await axios.post(
          `${environment.baseUrl}/userRegistration/refresh`,
          {}
        );

        // Check if the user has the ROLE_ADMIN role
        if (response.data && response.data.roles) {
          // Extract the roles from the response
          const roles = response.data.roles;


          // IF THE CURRENT USER  IS CLIENT
          if (roles.includes("ROLE_HORECA")) {
            navigate("/horeca/dashboard");
          } else if (roles.includes("ROLE_ADMIN")) {
            navigate("/admin/dashboard");
          }
          // Check if the user has the ROLE_ACCOUNTANT role
          else if (roles.includes("ROLE_ACCOUNTANT")) {
            dispatch({ type: AUTHSUCCESS, payload: { roles: roles } });
            if (currentPath === "/") {
              navigate("/accountant/dashboard");
            }
          } else {
            navigate("/auth/404", { replace: true });
          }
        }
      } catch (error) {
        if (
          error.response.status !== undefined &&
          error.response.status === 402
        ) {

          swal({
            title: "Richiesta non riuscita",
            text: ` ${formatToCapitalize(error.response.data.message)} `,
            icon: "warning",
            button: "OK",
            dangerMode: true,
          }).then(() => {
            navigate("/horeca/subscription/all"); // Replace "/dashboard" with the desired route
          });

          clearCookies();
        } else {
          clearCookies();

          navigate("/auth/login", { replace: true });
        }
      }
    };

    refreshToken();
  }, [navigate]);

  return children;
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

export default connect(mapStateToProps, { refreshUser })(AuthAccountantGuard);
