import {
  GETCURRENTSUBSCRIPTION,
  GETCURRENTSUBSCRIPTIONERROR,
  GETFREEPLANSTATE,
  GETPLANSTATE,
  GETSUBSCRIPTIONHISTORY,
  RESETCURRENTSUBSCRIPTION,
  RESETCURRENTSUBSCRIPTIONERROR,
} from "src/modules/subscription/service/SubscriptionActionTypes";
import { GETALLPLANSFORLANDINGPAGE, GETALLPLANSFORLANDINGPAGEFORACCOUNTANT, GETALLPLANSFORLANDINGPAGEFORHORECA } from "src/landingPage/service/LandingPageActionTypes";
import { GETALLPLANS } from "./../../modules/subscription/plans/service/PlansActionTypes";
import { GETALLPLANSADMIN } from "src/modules/admin/plans/service/PlansAdminActionTypes";
const initialState = {
  // PLANS
  allPlans: [],
  allPlansForLandingPageForAccountant: [],
  allPlansForLandingPageForHoreca: [],

  // SUBSCRIPTION
  currentSubscription: null,
  currentSubscriptionError: null,
  freePlanState: null,
  planState: null,

  // SUBSCRIPBTION HISTORY
  subscriptionHistory: null,
  currentPageSubscriptionHistory: 0,
  totalPagesSubscriptionHistory: 0,
  totalElementsSubscriptionHistory: 0,


  // PLANS ADMIN
  allPlansForAdmin: [],

  


};

export default function PlansReducer(state = initialState, action) {
  switch (action.type) {
    // PLANS
    case GETALLPLANS:
      return {
        ...state,
        allPlans: action.payload.data,
      };

    case GETALLPLANSFORLANDINGPAGEFORHORECA:
      return {
        ...state,
        allPlansForLandingPageForHoreca: action.payload.data,
      };

      
    case GETALLPLANSFORLANDINGPAGEFORACCOUNTANT:
      return {
        ...state,
        allPlansForLandingPageForAccountant: action.payload.data,
      };

    // SUBSCRIPTION
    case GETCURRENTSUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action.payload.data,
      };

    case GETCURRENTSUBSCRIPTIONERROR:
      return {
        ...state,
        currentSubscriptionError: action.payload.data,
      };

    // SUBSCRIPTION
    case RESETCURRENTSUBSCRIPTION:
      return {
        ...state,
        currentSubscription: null,
      };

    case RESETCURRENTSUBSCRIPTIONERROR:
      return {
        ...state,
        currentSubscriptionError: null,
      };

    case GETFREEPLANSTATE:
      return {
        ...state,
        freePlanState: action.payload.data,
      };

    case GETPLANSTATE:
      return {
        ...state,
        planState: action.payload.data,
      };

    case GETSUBSCRIPTIONHISTORY:
      return {
        ...state,
        subscriptionHistory: action.payload.data,
        currentPageSubscriptionHistory: action.payload.currentPage,
        totalPagesSubscriptionHistory: action.payload.totalPages,
        totalElementsSubscriptionHistory: action.payload.totalElements,
      };

      // ADMIN PART
      case GETALLPLANSADMIN:
        return {
          ...state,
          allPlansForAdmin: action.payload.data,
        };

    default: {
      return state;
    }
  }
}
