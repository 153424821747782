import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  getLastSystemOperation,
  subscribeToSystemOperations,
} from "src/modules/systemOperation/service/SystemOperationService";
import { Stack, Chip, Tooltip } from "@mui/material";
import LinearProgressWithLabel from "src/components/shared/LinearProgressWithLabel";
import { getJwt, hasClientRole } from "src/utils/decodeJwtCurrentUser";
const SuccesOperationChips = ({
  getLastSystemOperation,
  subscribeToSystemOperations,
}) => {
  const { lastOperation } = useSelector(
    (state) => state.systemOperationReducer
  );

  useEffect(() => {
    getLastSystemOperation();
    if (lastOperation !== null) {
      if (!lastOperation.passed) {
        subscribeToSystemOperations(lastOperation);
      }
    }
  }, [subscribeToSystemOperations, getLastSystemOperation]);

  // GET ROLES FROM JWT
  const isHoreca = hasClientRole(getJwt());
  const isAccountant = hasClientRole(getJwt());

  return (
    <div>
      {(isHoreca || isAccountant) & (lastOperation !== null) ? (
        !lastOperation.passed ? (
          <LinearProgressWithLabel />
        ) : ""
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    systemOperationReducer: state.systemOperationReducer,
  };
};
export default connect(mapStateToProps, {
  getLastSystemOperation,
  subscribeToSystemOperations,
})(SuccesOperationChips);
