import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  IconButton,
  Box,
  Badge,
  Menu,
  MenuItem,
  Avatar,
  Typography,
  Button,
  Chip,
  Grid,
} from "@mui/material";
import Scrollbar from "src/components/custom-scroll/Scrollbar";
import { FaRegCircleUser } from "react-icons/fa6";
import { iconUserInNotification } from "src/commons/constants/Constants";
import { IconBell, IconBellRinging } from "@tabler/icons";
import { Stack } from "@mui/system";
import { useSelector, connect } from "react-redux";
import { getLastNotification } from "src/modules/notification/service/NotificationService";
import { formatDateAgo } from "src/utils/formatDate";
const Notifications = ({ getLastNotification }) => {
  const [anchorEl2, setAnchorEl2] = useState(null);

  const lastFiveNotification = useSelector(
    (state) => state.notificationReducer.headerNotification
  );

  useEffect(() => {
    getLastNotification();
  }, []);

  const handleClickToNotificarionButtonIcon = (event) => {
    getLastNotification();
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          ...(anchorEl2 && {
            color: "primary.main",
          }),
        }}
        onClick={handleClickToNotificarionButtonIcon}
      >
        {lastFiveNotification.length > 0 ? (
          <Badge variant="dot" color="primary">
            <IconBellRinging size="21" stroke="1.5" />
          </Badge>
        ) : (
          <IconBell size="21" stroke="1.5" />
        )}
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Message Dropdown */}
      {/* ------------------------------------------- */}

      {lastFiveNotification.length > 0 && (
        <Menu
          id="msgs-menu"
          anchorEl={anchorEl2}
          keepMounted
          open={Boolean(anchorEl2)}
          onClose={handleClose2}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          sx={{
            "& .MuiMenu-paper": {
              width: "360px",
            },
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Notifications</Typography>
          </Stack>

          <Scrollbar sx={{ maxHeight: "385px" }}>
            {lastFiveNotification.map((notification, index) => (
              <Box key={index}>
                <MenuItem
                  sx={{
                    py: 2,
                    px: 4,
                    "&:hover": {
                      backgroundColor: "#EDE0D4", // Change this color to your preference
                      color: "#fff", // Optional: change text color on hover
                    },
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <Avatar sx={{ bgcolor: "transparent" }}>
                      {notification.notificationType.emoji}
                    </Avatar>
                    <Box>
                      <Typography my={1} variant="h5" sx={{ width: "100%" }}>
                        <Grid container justifyContent="space-between">
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            fontWeight={600}
                            sx={{ fontSize: "11px" }}
                          >
                            {notification.name}
                          </Typography>{" "}
                          <Typography
                            sx={{ color: "#c9cad1", fontSize: "11px" }}
                            variant="body2"
                          >
                            {formatDateAgo(notification.createdAt)}
                          </Typography>
                        </Grid>
                      </Typography>
                      {/* <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        fontWeight={600}
                        noWrap
                        sx={{
                          width: "240px",
                        }}
                      >
                        {notification.name}
                      </Typography> */}
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                        sx={{
                          width: "240px",
                          fontSize: "13px",
                        }}
                        noWrap
                      >
                        {notification.description}
                      </Typography>
                    </Box>
                  </Stack>
                </MenuItem>
              </Box>
            ))}
          </Scrollbar>
          <Box p={3} pb={1}>
            <Button
              to="/notification"
              variant="outlined"
              component={Link}
              color="primary"
              fullWidth
            >
              Vedi tutte Notifications
            </Button>
          </Box>
        </Menu>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationReducer: state.notificationReducer,
  };
};
export default connect(mapStateToProps, { getLastNotification })(Notifications);
