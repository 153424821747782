import { decode } from "jsonwebtoken";
import swal from "sweetalert";
import LOGOHORECA from "src/assets/images/logos/logohoreca.png";
export const decodeJWT = (token) => {
  if (token) {
    const getToken = token.split(".");

    return JSON.parse(window.atob(getToken[1]));
  }
};

export const decodeAllJWT = (token) => {
  if (token) {
    const getToken = token.split(".");

    return JSON.parse(window.atob(getToken));
  }
};

// Function to check if the user has the ROLES_CLIENT role
export const hasClientRole = (refreshToken) => {
  try {
    const decodedToken = decode(refreshToken);
    const roles = decodedToken?.roles || [];
    return roles.includes("ROLE_HORECA");
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};

// Function to check if the user has the ROLE_ADMIN role
export const hasAdminRole = (refreshToken) => {
  try {
    const decodedToken = decode(refreshToken);
    const roles = decodedToken?.roles || [];
    return roles.includes("ROLE_ADMIN");
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};

// Function to check if the user has the ROLE_ACCOUNTANT role
export const hasAccountantRole = (refreshToken) => {
  try {
    const decodedToken = decode(refreshToken);
    const roles = decodedToken?.roles || [];
    return roles.includes("ROLE_ACCOUNTANT");
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};

export const jwtHeader = () => {
  return { Authorization: `Bearer ${localStorage.getItem("refresh_token")}` };
};

export const getJwt = () => {
  return localStorage.getItem("refresh_token");
};

export const userDoesntHaveInvocesChecker = async (
  getOverViewDashboardTopCard,
  getSubscriptionState,
  navigate
) => {
  try {

    const results = await Promise.all([
      getOverViewDashboardTopCard(),
      getSubscriptionState(),
    ]);

    // GET ROLES FROM JWT
    const isHoreca = hasClientRole(getJwt());

    const checkUserDoesntHaveInvoices = results[0]; // Assuming getOverViewDashboardTopCard() is the last promise

    const checkIfTheUserDoesntHaveSubscription = results[1];


    if (
      checkUserDoesntHaveInvoices !== null &&
      checkUserDoesntHaveInvoices !== undefined &&
      checkUserDoesntHaveInvoices.totalSuppliers === undefined &&
      checkUserDoesntHaveInvoices.totalProducts === undefined &&
      checkUserDoesntHaveInvoices.totalCategories === undefined &&
      checkUserDoesntHaveInvoices.totalSubCategories === undefined &&
      checkUserDoesntHaveInvoices.totalInvoices === undefined
    ) {
      // Create the custom icon element
      const customIcon = document.createElement("img");
      customIcon.src = LOGOHORECA; // Path to your custom icon image
      customIcon.alt = "Custom Icon";
      customIcon.style.width = "120px"; // Width of the image
      customIcon.style.height = "120px"; // Height of the image
      customIcon.style.display = "block";
      customIcon.style.margin = "0 auto"; // Center the icon
      customIcon.style.borderRadius = "7px"; // Border radius for the image

      // Define the SweetAlert configuration with the custom icon
      const swalConfig = (text) => ({
        content: {
          element: "div",
          attributes: {
            innerHTML: `
        <div style="text-align: center;">
          <img  src="${customIcon.src}" alt="${customIcon.alt}" style="width: ${customIcon.style.width}; border-radius: ${customIcon.style.borderRadius}; height: ${customIcon.style.height}; margin: ${customIcon.style.margin};">
          <h3 style="margin-top: 30px;">Benvenuto in Horeca</h3>
          <p style="margin-top: 30px;">${text}</p>
        </div>
      `,
          },
        },
        button: "OK",
        dangerMode: true,
      });

      if (isHoreca && checkIfTheUserDoesntHaveSubscription === undefined) {
        await swal(swalConfig(`Clicca su ok per selezionare un piano`));
        navigate("/horeca/subscription/all");
      } else {
        await swal(swalConfig(`Clicca qui sotto per caricare le tue fatture`));
        navigate("/horeca/invoice/upload");
      }
    }
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};
