import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogoHoreca from "src/assets/images/logos/logohoreca.png";
import { styled } from "@mui/material";

const Logo = ({ toggleSideBar }) => {
  const customizer = useSelector((state) => state.customizer);
  const LinkStyled = styled(Link)(() => ({
    height: customizer.TopbarHeight,
    width: customizer.isCollapse ? "40px" : "180px",
    display: "block",
    zIndex: 999999, // Apply z-index here

    img: {
      zIndex: 999999, // Apply z-index here
      borderRadius: "0.5rem", // Assuming 'rounded' is equivalent to a radius
      height: toggleSideBar ? "50px" : customizer.TopbarHeightImg,
    },
  }));


  

  return (
    <LinkStyled to="/">
      <img
        className="rounded mt-2"
        src={LogoHoreca}
        alt="horeca_logo"
        height={toggleSideBar ? "50px" : customizer.TopbarHeightImg}
      />
    </LinkStyled>
  );
};

export default Logo;
