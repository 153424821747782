import {
  CLEARCOUPON,
  GETALLCOUPON,
} from "src/modules/admin/discount/service/AdminDiscountActionTypes";

import {
  GETMYACTIVECLIENTCOUPON,
  RESTETHEACTIVECOUPON,
} from "src/modules/subscription/service/SubscriptionActionTypes";

const initialState = {
  // LIST OF COUPON
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  allCouponList: [],

  // ACTIVE COUPON FOR CLIENT
  activeCouponForUser: null,
};

export default function AdminDiscountReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLCOUPON:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        allCouponList: action.payload.data,
      };

    case CLEARCOUPON:
      return {
        ...state,
        currentPage: 0,
        totalPages: 0,
        totalElements: 0,
        allCouponList: [],
      };

    case GETMYACTIVECLIENTCOUPON:
      return {
        activeCouponForUser: action.payload,
      };

    case RESTETHEACTIVECOUPON:
      return {
        activeCouponForUser: null,
      };

    default: {
      return state;
    }
  }
}
