import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { CircularProgressWithLabel } from "src/modules/systemOperation/components/CircularProgressOperation";

export default function LinearProgressWithLabel() {
  const { lastOperation } = useSelector(
    (state) => state.systemOperationReducer
  );


  return (
    <>
      {lastOperation !== null && lastOperation !== undefined && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ height: "10px", width: "60px", mr: 1 }}>
            <LinearProgress
              style={{ width: "100%", marginTop: "4px" }}
              value={lastOperation.currentProcessedInvoiceCount}
              max={lastOperation.totalInvoicesCount}
            />
          </Box>

          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2">
              {`${lastOperation.currentProcessedInvoiceCount} / ${lastOperation.totalInvoicesCount}`}
            </Typography>
          </Box>

          <Box ml={2}>
            <CircularProgressWithLabel
              value={lastOperation.operationProgressItems}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
