import React, { useState, useEffect } from "react";
import { Box, Avatar, IconButton, useMediaQuery } from "@mui/material";
import { decodeJWT } from "src/utils/decodeJwtCurrentUser";
import { BiFullscreen } from "react-icons/bi";
import { BiExitFullscreen } from "react-icons/bi";
import { fullWidthIconConfig } from "src/commons/constants/Constants";

const Profile = () => {
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const [userAuthentified, setUserAuthentified] = useState(null);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    setUserAuthentified(decodeJWT(localStorage.getItem("refresh_token")));

  }, []);

  // TOGGLE TO FULL SCREEN

  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      // Enter fullscreen mode
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((error) => {
          console.error("Error entering fullscreen mode:", error);
        });
    } else {
      // Exit fullscreen mode
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((error) => {
          console.error("Error exiting fullscreen mode:", error);
        });
    }
  };

  return (
    <Box>
      {lgUp && (
        <IconButton
          size="large"
          aria-label="show 11 new notifications"
          color="inherit"
          aria-controls="msgs-menu"
          aria-haspopup="true"
          sx={{
            ...(typeof anchorEl2 === "object" && {
              color: "primary.main",
            }),
          }}
          onClick={handleFullscreenToggle}
        >
          {userAuthentified && (
            <>
              {isFullscreen ? (
                <BiExitFullscreen style={fullWidthIconConfig} />
              ) : (
                <BiFullscreen style={fullWidthIconConfig} />
              )}
            </>
          )}
        </IconButton>
      )}

      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          ...(typeof anchorEl2 === "object" && {
            color: "primary.main",
          }),
        }}
        onClick={handleClick2}
      >
        {userAuthentified && (
          <Avatar
            sx={{
              width: 35,
              height: 35,
              bgcolor: "#84665b",
            }}
            alt={userAuthentified.company_name}
          >
            {userAuthentified.company_name.charAt(0).toUpperCase()}
          </Avatar>
        )}
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Menu Dropdown */}
      {/* ------------------------------------------- */}
      {/*       
      <Menu
        id="msgs-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={{
          '& .MuiMenu-paper': {
            width: '360px',
          },
        }}
      >
        <Scrollbar sx={{ height: '100%', maxHeight: '85vh' }}>
          <Box p={3}>
            <Typography variant="h5">User Profile</Typography>
            <Stack direction="row" py={3} spacing={2} alignItems="center">
              <Avatar src={ProfileImg} alt={ProfileImg} sx={{ width: 95, height: 95 }} />
              <Box>
                <Typography variant="subtitle2" color="textPrimary" fontWeight={600}>
                  Mathew Anderson
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Designer
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <IconMail width={15} height={15} />
                  info@modernize.com
                </Typography>
              </Box>
            </Stack>
            <Divider />
            {dropdownData.profile.map((profile) => (
              <Box key={profile.title}>
                <Box sx={{ py: 2, px: 0 }} className="hover-text-primary">
                  <Link to={profile.href}>
                    <Stack direction="row" spacing={2}>
                      <Box
                        width="45px"
                        height="45px"
                        bgcolor="primary.light"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Avatar
                          src={profile.icon}
                          alt={profile.icon}
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: 0,
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          fontWeight={600}
                          color="textPrimary"
                          className="text-hover"
                          noWrap
                          sx={{
                            width: '240px',
                          }}
                        >
                          {profile.title}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                          sx={{
                            width: '240px',
                          }}
                          noWrap
                        >
                          {profile.subtitle}
                        </Typography>
                      </Box>
                    </Stack>
                  </Link>
                </Box>
              </Box>
            ))}
            <Box mt={2}>
              <Box bgcolor="primary.light" p={3} mb={3} overflow="hidden" position="relative">
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography variant="h5" mb={2}>
                      Unlimited <br />
                      Access
                    </Typography>
                    <Button variant="contained" color="primary">
                      Upgrade
                    </Button>
                  </Box>
                  <img src={unlimitedImg} alt="unlimited" className="signup-bg"></img>
                </Box>
              </Box>
              <Button
                to="/auth/login"
                variant="outlined"
                color="primary"
                component={Link}
                fullWidth
              >
                Logout
              </Button>
            </Box>
          </Box>
        </Scrollbar>
      </Menu> */}
    </Box>
  );
};

export default Profile;
