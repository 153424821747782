import {
  GETALLPRODUCTBYCUSTOMERUUID,
  GETALLISACODEBYCUSTOMERUUID,GETPURCHASEHISTORYBYPERIODANDCUSTOMERUUIDANDPRODUCT
} from "src/modules/accountant/service/AccountantActionType";

const initialState = {
  // ALL Accountant
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  productsList: [],
  isaCode: [],
  purchaseHistoryProductAccountant: [],
};

export default function AccountantProductReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLPRODUCTBYCUSTOMERUUID:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        productsList: action.payload.data,
      };

    case GETALLISACODEBYCUSTOMERUUID:
      return {
        ...state,
        isaCode: action.payload.data,
      };


      case GETPURCHASEHISTORYBYPERIODANDCUSTOMERUUIDANDPRODUCT:
        return {
          ...state,
          purchaseHistoryProductAccountant: action.payload.data,
        };
  

    default: {
      return state;
    }
  }
}
