import {
  GETLASTESTNOTIFICATION,
  GETALLNOTIFICATION,
  CLEARNOTIFICATIONS,
} from "src/modules/notification/service/NotificationActionTypes";

const initialState = {
  headerNotification: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  notificationList: [],
};

export default function NotificationReducer(state = initialState, action) {
  switch (action.type) {
    case GETLASTESTNOTIFICATION:
      return {
        ...state,
        headerNotification: action.payload.data,
      };

    case GETALLNOTIFICATION:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        notificationList: action.payload.data,
      };

    case CLEARNOTIFICATIONS:
      return {
        ...state, 
        notificationList: [],
      };

    default: {
      return state;
    }
  }
}
