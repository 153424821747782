import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  DialogActions,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CiDiscount1 } from "react-icons/ci";
import { FaRegUserCircle } from "react-icons/fa";
import { IoMdTimer } from "react-icons/io";
import { IoBusinessOutline, IoPricetagOutline } from "react-icons/io5";
import { TbFileInvoice } from "react-icons/tb";
import { connect } from "react-redux";
import {
  accountType,
  accountantType,
  iconInInputStyle,
  planMode,
} from "src/commons/constants/Constants";
import PageContainer from "src/components/container/PageContainer";
import ParentCard from "src/components/shared/ParentCard";
import "src/modules/admin/discount/style/discount.css";
import { createNewPlan } from "./service/PlansServiceForAdmin";

const CreatePlans = ({ createNewPlan }) => {
  const {
    control,
    handleSubmit,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const [loadingButtonAction, setLoadingButtonAction] = useState(false);
  const [selectedAccountType, setSelectedAccountType] = useState(false);

  const addNewPlan = async (formData) => {
    //setLoadingButtonAction(true);
    const updatedPayload = { ...formData }; // Spread the formData object
    updatedPayload.currency = "Euro";
    updatedPayload.currencyEmoji = "€";
    delete updatedPayload.selectedPlanMode;
    delete updatedPayload.selectedAccountType;


    const returnFromFunction = createNewPlan(updatedPayload);

    if (returnFromFunction.clearInput) {
      reset();
    }

    setLoadingButtonAction(returnFromFunction.loadingButton);
  };

  const handleSelectedAccountType = (selectedAccountType) => {

    setValue("planType", selectedAccountType.value); // Setting the value in React Hook Form
    setSelectedAccountType(selectedAccountType.value);
  };

  const handleSelectedPlanMode = (planMode) => {

    setValue("mode", planMode.value); // Setting the value in React Hook Form
  };

  return (
    <PageContainer
      title={`Creare piano`}
      description="this is add new plan page for admin"
    >
      <Grid container mt={4}>
        <ParentCard title="Creare piano">
          <form onSubmit={handleSubmit(addNewPlan)}>
            <Grid container spacing={3} my={4}>
              <Grid item xs={6}>
                <Typography my={2} variant="body2">
                  Nome dei Piani :
                </Typography>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      {...register("name", { required: true })}
                      error={errors.name ? true : false}
                      helperText={errors.name && "Nome dell piani è richiesto"}
                      variant="outlined"
                      placeholder="Nome dell Plan"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <IoBusinessOutline style={iconInInputStyle} />
                        ),
                      }}
                      onChange={(e) => field.onChange(e.target.value)} // Add onChange handler
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography my={2} variant="body2">
                  Descrizione :
                </Typography>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      {...register("description", { required: true })}
                      error={errors.description ? true : false}
                      helperText={
                        errors.description && "Descrizione è richiesto"
                      }
                      variant="outlined"
                      placeholder="Descrizione dell Piano"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <IoBusinessOutline style={iconInInputStyle} />
                        ),
                      }}
                      onChange={(e) => field.onChange(e.target.value)} // Add onChange handler
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography my={2} variant="body2">
                  Tipo di Piano :
                </Typography>

                <Controller
                  control={control}
                  name="selectedAccountType"
                  rules={{ required: "Type account è richiesto" }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      className="accountType"
                      disablePortal
                      disableClearable
                      options={accountType}
                      value={value || null}
                      size="medium"
                      sx={{
                        width: "auto",
                        height: "auto",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(event, newValue) => {
                        onChange(newValue);
                        handleSelectedAccountType(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Seleziona tipo di account"
                          sx={{
                            textAlign: "center",
                          }}
                          error={Boolean(errors.selectedAccountType)}
                          helperText={
                            errors.selectedAccountType &&
                            errors.selectedAccountType.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography my={2} variant="body2">
                  Modalità del Piano
                </Typography>

                <Controller
                  control={control}
                  name="selectedPlanMode"
                  rules={{ required: "Modalità del Piano è richiesto" }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      className="accountType"
                      disablePortal
                      disableClearable
                      options={planMode}
                      value={value || null}
                      size="medium"
                      sx={{
                        width: "auto",
                        height: "auto",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(event, newValue) => {
                        onChange(newValue);
                        handleSelectedPlanMode(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Seleziona plan mode"
                          sx={{
                            textAlign: "center",
                          }}
                          error={Boolean(errors.selectedAccountType)}
                          helperText={
                            errors.selectedAccountType &&
                            errors.selectedAccountType.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography my={2} variant="body2">
                  Totale Fatture Caricabili :
                </Typography>

                <Controller
                  name="totalInvoicesCanUpload"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) =>
                      parseFloat(value) >= 1 ||
                      "Discount percentage must be 0 or greater",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      {...register("totalInvoicesCanUpload", {
                        required: true,
                      })}
                      error={errors.totalInvoicesCanUpload ? true : false}
                      helperText={
                        errors.totalInvoicesCanUpload &&
                        "totalInvoicesCanUpload è richiesto"
                      }
                      type="number"
                      min="1"
                      variant="outlined"
                      placeholder="Total Invoice Can Upload ..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <TbFileInvoice style={iconInInputStyle} />
                        ),
                      }}
                      onChange={(e) => field.onChange(e.target.value)} // Add onChange handler
                    />
                  )}
                />
              </Grid>

              {selectedAccountType === accountantType && (
                <Grid item xs={4}>
                  <Typography my={2} variant="body2">
                    Massimo Cliente :
                  </Typography>

                  <Controller
                    name="maxClient"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      validate: (value) =>
                        parseFloat(value) >= 1 || "Max Client",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        type="number"
                        {...register("maxClient", {
                          required: true,
                        })}
                        error={errors.maxClient ? true : false}
                        helperText={
                          errors.maxClient && "Massimo Cliente è richiesto"
                        }
                        variant="outlined"
                        placeholder="Max Client ..."
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <FaRegUserCircle style={iconInInputStyle} />
                          ),
                        }}
                        onChange={(e) => field.onChange(e.target.value)} // Add onChange handler
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <Typography my={2} variant="body2">
                Prezzo mensile :
                </Typography>
                <Controller
                  name="monthlyPrice"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) =>
                      parseFloat(value) > 0 ||
                      "Discount percentage must be 0 or greater",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      {...register("monthlyPrice", { required: true })}
                      error={errors.monthlyPrice ? true : false}
                      helperText={
                        errors.monthlyPrice && "Prezzo mensile è richiesto"
                      }
                      required
                      type="number"
                      variant="outlined"
                      placeholder="Prezzo mensile..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <IoPricetagOutline style={iconInInputStyle} />
                        ),
                        endAdornment: "€",
                      }}
                      onChange={(e) => field.onChange(e.target.value)} // Add onChange handler
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography my={2} variant="body2">
                Durata in Mesi:
                </Typography>
                <Controller
                  name="durationMonths"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) =>
                      parseFloat(value) >= 1 ||
                      "Durata in Mesi percentage must be 0 or greater",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      {...register("durationMonths", { required: true })}
                      error={errors.durationMonths ? true : false}
                      helperText={
                        errors.durationMonths && "Durata in Mesi è richiesto"
                      }
                      required
                      variant="outlined"
                      placeholder="Duration Months :"
                      fullWidth
                      InputProps={{
                        startAdornment: <IoMdTimer style={iconInInputStyle} />,
                      }}
                      onChange={(e) => field.onChange(e.target.value)} // Add onChange handler
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography my={2} variant="body2">
                  {" "}
                  Sconto :
                </Typography>

                <Controller
                  name="discountPercentage"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) =>
                      (parseFloat(value) >= 0 && parseFloat(value) <= 100) ||
                      "Sconto percentage must be 0 or greater",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      {...register("discountPercentage", { required: true })}
                      error={errors.discountPercentage ? true : false}
                      helperText={
                        errors.discountPercentage &&
                        "Sconto è richiesto"
                      }
                      required
                      variant="outlined"
                      placeholder="Discount Percentage"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <CiDiscount1 style={iconInInputStyle} />
                        ),
                        endAdornment: "%",
                      }}
                      onChange={(e) => field.onChange(e.target.value)} // Add onChange handler
                    />
                  )}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <LoadingButton
                type="submit"
                className="buttonCreateCoupon buttonApplyCoupon"
                size="large"
                loadingPosition="end"
                loading={loadingButtonAction}
                disabled={Object.keys(errors).length > 0} // Disable button if there are errors
              >
                <span
                  className={
                    loadingButtonAction
                      ? "loadingButtonStyleApply"
                      : "loadingButtonStyleNotApply"
                  }
                >
                  Modifica
                </span>
              </LoadingButton>
            </DialogActions>
          </form>
        </ParentCard>
      </Grid>
    </PageContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    plansReducer: state.plansReducer,
  };
};
export default connect(mapStateToProps, { createNewPlan })(CreatePlans);
