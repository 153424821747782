import axios from "axios";
import {
  GETLASTESTNOTIFICATION,
  GETALLNOTIFICATION,
  APPENDNOTIFICATIONS,
  CLEARNOTIFICATIONS,
} from "./NotificationActionTypes";
import { environment } from "src/environement/environment";

export const getLastNotification = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${environment.baseUrl}/notification/all?page=0&limit=5`
    );


    dispatch({
      type: GETLASTESTNOTIFICATION,
      payload: {
        data: response.data.data,
      },
    });
  } catch (e) {
    console.log("INVALID", e);
  }
};

export const getAllNotification = (page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${environment.baseUrl}/notification/all?page=${page}&limit=5`
    );


    dispatch({
      type: GETALLNOTIFICATION,
      payload: {
        currentPage: response.data.currentPage,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
        data: response.data.data,
      },
    });
  } catch (e) {
    console.log("INVALID", e);
  }
};

export const appendNotifications = (notifications, currentPage) => {
  return {
    type: APPENDNOTIFICATIONS,
    payload: {
      data: notifications,
      currentPage,
    },
  };
};

export const clearNotifications = () => {
  return {
    type: CLEARNOTIFICATIONS,
  };
};
