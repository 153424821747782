import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  getFreeTrialState,
  getSubscriptionState,
} from "../service/SubscriptionService";
import { Chip, Box, Grid, Tooltip } from "@mui/material";
import { getJwt, hasClientRole } from "src/utils/decodeJwtCurrentUser";
import Spinner from "src/components/shared/Spinner";
import { useNavigate } from "react-router-dom";

const FreeTrialChips = ({ getFreeTrialState, getSubscriptionState }) => {
  const { freePlanState, planState } = useSelector(
    (state) => state.plansReducer
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    Promise.all([getFreeTrialState(), getSubscriptionState()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const isHoreca = hasClientRole(getJwt());

  const redirectToSubscription = () => {
    if (isHoreca) {
      navigate("/horeca/subscription/all");
    } else {
      navigate("/accountant/subscription/all");
    }
  };

  return (
    <>
      {loading ? (
        ""
      ) : (
        <>
          {isHoreca && freePlanState !== null && (
            <div>
              {freePlanState !== null && !freePlanState.expired && (
                <Grid container spacing={2}>
                  <Grid item>
                    <Chip
                      color="success"
                      variant="outlined"
                      size="large"
                      label={` Fatture   ${freePlanState.freeTrialQuota.totalFreeInvoice} / ${freePlanState.freeTrialQuota?.freeInvoiceUploadLeft}`}
                    />
                  </Grid>

                  <Grid item>
                    <Chip
                      color="primary"
                      variant="outlined"
                      size="large"
                      label={` Prodotti ${freePlanState.freeTrialQuota.totalFreeProducts} /  ${freePlanState.freeTrialQuota?.freeProductUploadLeft}`}
                    />
                  </Grid>
                  {/* TODO:: FREE TRIAL  */}
                  {/* <Grid item>
                    <Chip
                      onClick={redirectToSubscription}
                      color="info"
                      variant="outlined"
                      size="large"
                      label={` Free Trial `}
                      sx={{
                        background: "#0099FF !important",
                        color: "#fff !important",
                        fontSize: "18px",
                        cursor: "pointer",
                        "&:hover": {
                          background: "#0e8beb !important",
                          color: "#fff !important",
                        },
                      }}
                    />
                  </Grid> */}
                </Grid>
              )}
              {planState !== null &&
                freePlanState !== null &&
                freePlanState.expired && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Tooltip title="La fattura è gestita allo stesso modo delle altre componenti del tuo account">
                        <Chip
                          color="success"
                          variant="outlined"
                          size="large"
                          label={` Fatture     ${planState?.totalInvoiceUpload} / ${planState?.invoiceUploadLeft}`}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
            </div>
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    plansReducer: state.plansReducer,
  };
};
export default connect(mapStateToProps, {
  getFreeTrialState,
  getSubscriptionState,
})(FreeTrialChips);
