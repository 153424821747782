import {
    GETALLSTATUSTYPE
  } from "src/modules/statusType/service/StatusTypeActionType";
  
  const initialState = {
   
    statusTypeList: [],
  };
  
  export default function StatusTypeReducer(state = initialState, action) {
    switch (action.type) {
    

      case GETALLSTATUSTYPE:
        return {
          ...state, 
          statusTypeList: action.payload.data,
        };
  
      default: {
        return state;
      }
    }
  }
  