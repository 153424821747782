import axios from "axios";
import { environment } from "src/environement/environment";
import {
  GETALLSYSTEMOPERATION,
  GETLASTSYSTEMOPERATION,
} from "./SystemOperationActionTypes";
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import { jwtHeader } from "src/utils/decodeJwtCurrentUser";

// Function to fetch last system operation from the backend
export const getLastSystemOperation = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${environment.baseUrl}/system_operation/myLast`
    );


    dispatch({
      type: GETLASTSYSTEMOPERATION,
      payload: response.data,
    });

    return response;
  } catch (e) {
    console.log("INVALID", e);
  }
};

export const getLastSystemOperationForInvoice = async () => {
  try {
    const response = await axios.get(
      `${environment.baseUrl}/system_operation/myLast`
    );


    return response;
  } catch (e) {
    console.log("INVALID", e);
  }
};

let stompClientInstance;

export const subscribeToSystemOperations =
  (systemOperationTopic) => async (dispatch) => {
    try {
      // Create a SockJS instance and connect to the WebSocket server
      const socket = new SockJS(`${environment.baseUrl}/horeca-app`);
      const stompClient = Stomp.over(socket);
      stompClientInstance = stompClient; // Store the stompClient instance globally

      // Connect to the WebSocket server without sending any headers
      stompClient.connect(jwtHeader(), () => {

        stompClient.subscribe(
          `/systemOperations/status/${systemOperationTopic.operationTrackerToken}`,
          // `/systemOperations/status/mTflYerIjqFA38UByT1kwYTCRgiHRkBA`,
          (message) => {
            // Handle incoming message from the WebSocket server
            dispatch({
              type: GETLASTSYSTEMOPERATION,
              payload: JSON.parse(message.body),
            });
          },
          jwtHeader() || {}
        );
      });
    } catch (e) {
      console.log("INVALID", e);
    }
  };

// Clean up function
export const disconnectWebSocket = () => async (dispatch) => {
  if (stompClientInstance) {
    stompClientInstance.disconnect();
  }
};

export const getAllSystemOperation = (page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${environment.baseUrl}/system_operation/allMyOperations?page=${page}&limit=10`
    );


    dispatch({
      type: GETALLSYSTEMOPERATION,
      payload: {
        currentPage: response.data.currentPage,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
        data: response.data.data,
      },
    });

    return response;
  } catch (e) {
    console.log("INVALID", e);
  }
};
