import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import axios from "axios";
import { environment } from "src/environement/environment";
import { refreshUser } from "src/modules/account/service/AuthAction";
import { AUTHSUCCESS } from "src/modules/account/service/AuthTypes";
import { clearCookies } from "src/utils/cookiesTools";

const AuthAdminGuard = ({ children, refreshUser }) => {
  const { isAuthenticated } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const refreshToken = async () => {

   
      const currentPath = location.pathname;

      try {
        const response = await axios.post(
          `${environment.baseUrl}/userRegistration/refresh`,
          {}
        );
        

        // Extract the roles from the response if available

        // Check if the user has the ROLE_ADMIN role
        if (response.data && response.data.roles) {
          // Extract the roles from the response
          const roles = response.data.roles;

          // IF THE CURRENT USER  IS CLIENT
          if (roles.includes("ROLE_HORECA")) {
           
            navigate("/horeca/dashboard");

          }

          else if (roles.includes("ROLE_ACCOUNTANT")) {
            navigate("/accountant/dashboard");
          }

          // Check if the user has the ROLE_ADMIN role
          else if (roles.includes("ROLE_ADMIN")) {
            dispatch({ type: AUTHSUCCESS, payload: { roles: roles } });
            if (currentPath === "/") {
              navigate("/admin/dashboard");
            }
          } else {
            navigate("/auth/404", { replace: true });
          }
        } else {
          // Handle case where roles property is missing in the response
          console.error(
            "Error refreshing token: Roles property is missing in the response"
          );
          navigate("/auth/login", { replace: true });
        }
      } catch (error) {
        clearCookies();

        console.error("Error refreshing token:", error);

        navigate("/auth/login", { replace: true });
      }
    };

    refreshToken();
  }, [navigate]);

  useEffect(() => {
    refreshUser();
    if (isAuthenticated && window.location.pathname === "/auth/login") {
      navigate("/admin/dashboard", { replace: true });
    }
  }, [isAuthenticated, navigate, refreshUser]);
 
  return children;
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

export default connect(mapStateToProps, { refreshUser })(AuthAdminGuard);
