import {
  GETSTATISTIQUEPRODUCTS,
  GETREVENUEBYCATEGORY,
  GETMOSTPOPULARPRODUCTS,
  GETTOPINVOICEBYTOTALAMOUNT,
  GETTOTALREVENUEPERYEAR,
  GETINVOICESTATISTIQUE,
  GETUSERSENGAGEMENT,
  GETCOSTUMERSERVICE,
  GETFINANCIALMETRICS,
  GETDAILYINVOICE,
  GETMONTHLYINVOICE,
  GETWEEKLYINVOICE,
  GETYEARLYINVOICE,
  GETALLPRODUCTBYADMIN,
  GETALLSUPPLIERBYADMIN,
  GETINVOICEPERPERIODE,
  GETINVOICEPERPERIODEPERCOSTUMER,
  GETINVOICEPERPERIODEPERPRODUCT,
  GETINVOICEPERPERIODEPERSUPPLIER,
  GETTRACKINGPRODUCT,
  GETACCOUNTANTMETRICS,
} from "src/modules/admin/dashboard/service/DashboardActionTypes";
const initialState = {
  statistiqueProduct: {},
  revenueByCategory: [],
  topSalesProducts: [],
  totalRevenuePerYear: [],
  topInvoiceByTotalAmount: [],

  statistiqueInvoice: {},
  userEngagement: {},
  customerService: {},
  financialMetrics: {},

  // HORECA DASHBOARD ADMIN
  dailyInvoice: {},
  monthlyInvoice: {},
  weeklyInvoice: {},
  yearlyInvoice: {},

  productsList: [],
  supplierList: [],

  invoicePerPeriod: {},
  invoicePerPeriodSupplier: {},
  invoicePerPeriodProduct: {},
  invoicePerPeriodCostumer: {},
  productTrack: {},

  // ACCOUNTANT
  accountantMetrics: {},
};

export default function AdminDashboardReducer(state = initialState, action) {
  switch (action.type) {
    // ACCOUNTANT
    case GETACCOUNTANTMETRICS:
      return {
        ...state,
        accountantMetrics: action.payload.data,
      };

    case GETSTATISTIQUEPRODUCTS:
      return {
        ...state,
        statistiqueProduct: action.payload.data,
      };

    case GETINVOICEPERPERIODE:
      return {
        ...state,
        invoicePerPeriod: action.payload.data,
      };

    case GETTRACKINGPRODUCT:
      return {
        ...state,
        productTrack: action.payload.data,
      };

    case GETINVOICEPERPERIODEPERCOSTUMER:
      return {
        ...state,
        invoicePerPeriodCostumer: action.payload.data,
      };

    case GETINVOICEPERPERIODEPERPRODUCT:
      return {
        ...state,
        invoicePerPeriodProduct: action.payload.data,
      };

    case GETINVOICEPERPERIODEPERSUPPLIER:
      return {
        ...state,
        invoicePerPeriodSupplier: action.payload.data,
      };

    case GETALLPRODUCTBYADMIN:
      return {
        ...state,
        productsList: action.payload.data,
      };

    case GETALLSUPPLIERBYADMIN:
      return {
        ...state,
        supplierList: action.payload.data,
      };

    case GETUSERSENGAGEMENT:
      return {
        ...state,
        userEngagement: action.payload.data,
      };

    case GETDAILYINVOICE:
      return {
        ...state,
        dailyInvoice: action.payload.data,
      };

    case GETMONTHLYINVOICE:
      return {
        ...state,
        monthlyInvoice: action.payload.data,
      };

    case GETWEEKLYINVOICE:
      return {
        ...state,
        weeklyInvoice: action.payload.data,
      };

    case GETYEARLYINVOICE:
      return {
        ...state,
        yearlyInvoice: action.payload.data,
      };

    case GETFINANCIALMETRICS:
      return {
        ...state,
        financialMetrics: action.payload.data,
      };

    case GETCOSTUMERSERVICE:
      return {
        ...state,
        customerService: action.payload.data,
      };
    case GETREVENUEBYCATEGORY:
      return {
        ...state,
        revenueByCategory: action.payload.data,
      };

    case GETMOSTPOPULARPRODUCTS:
      return {
        ...state,
        topSalesProducts: action.payload.data,
      };

    case GETTOTALREVENUEPERYEAR:
      return {
        ...state,
        invoicetrtotalRevenuePerYearacker: action.payload.data,
      };

    case GETTOPINVOICEBYTOTALAMOUNT:
      return {
        ...state,
        topInvoiceByTotalAmount: action.payload.data,
      };
    case GETINVOICESTATISTIQUE:
      return {
        ...state,
        statistiqueInvoice: action.payload.data,
      };
    default: {
      return state;
    }
  }
}
