export const formatRowValue = (row, numberOfChart) => {
  if (row !== null && row !== undefined && row.length > 0) {
    return row.length > numberOfChart
      ? `${row.substring(0, numberOfChart).toLowerCase()} ...`
      : row.toLowerCase();
  }

  return null;
};

export const formatRowValuePlainText = (row, numberOfChart) => {
  if (row !== null && row !== undefined && row.length > 0) {
    return row.length > numberOfChart
      ? `${row.substring(0, numberOfChart)} ...`
      : row;
  }

  return null;
};

export const formatToLowerCase = (row) => {
  if (row !== null && row !== undefined && row.length > 0) {
    return row.toLowerCase();
  }
};

export const formatToUpperCase = (value) => {
  return value
    ? value
        // Split camel case into words
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        // Capitalize the first letter of each word
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";
};

export const formatToCapitalize = (str) => {
  if (!str || typeof str !== "string") return str;
  return str.toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase());
};

export function autoBreak(label) {
  const maxLength = 16;
  const lines = [];

  for (let word of label.split(" ")) {
    if (lines.length == 0) {
      lines.push(word);
    } else {
      const i = lines.length - 1;
      const line = lines[i];

      if (line.length + 1 + word.length <= maxLength) {
        lines[i] = `${line} ${word}`;
      } else {
        lines.push(word);
      }
    }
  }

  return lines;
}

export const formatAccountType = (accountType) => {
  return { label: formatToCapitalize(accountType), value: accountType };
};

export const extractText = (label) => {
  const text = label.substring(2);
  return text;
};


export function formatValueByRemovingUnderScore(input) {
   // Check if input is null or undefined, return empty string
   if (input === null || input === undefined) {
    return '';
  }
  // Use regular expression to replace underscores with spaces
  // and convert the string to lowercase
  return input.replace(/_/g, ' ').toLowerCase();
}


export function formatRole(role) {
  const normalizedRole = role.toUpperCase(); // Normalize the role to uppercase for comparison

  switch (normalizedRole) {
    case 'HORECA':
      return 'Ho.Re.Ca';
    case 'ACCOUNTANT':
      return 'Studi di settore';
    default:
      return role; // Returns the original role if no match is found
  }
}