import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { GrPrevious } from "react-icons/gr";
import { iconInPreviousStyle } from "src/commons/constants/Constants";
import { useNavigate } from "react-router-dom";

const ParentCard = ({ title, children, footer, allowBack }) => {
  const customizer = useSelector((state) => state.customizer);

  // GET HOOKS TO GET URL IN APPS
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Card
      sx={{ padding: 0 }}
      elevation={customizer.isCardShadow ? 9 : 0}
      variant={!customizer.isCardShadow ? "outlined" : undefined}
    >
      <CardHeader
        title={
          <Typography mt={1} mb={1} variant="h4">
            {allowBack === null ||
              (allowBack === undefined && (
                <GrPrevious
                  onClick={handleGoBack}
                  style={iconInPreviousStyle}
                />
              ))}
            {title}
          </Typography>
        }
      />
      <Divider />
      <CardContent className="fromTable">{children}</CardContent>
      {footer ? (
        <>
          <Divider />
          <Box p={2}>{footer}</Box>
        </>
      ) : null}
    </Card>
  );
};

ParentCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  footer: PropTypes.node,
};

export default ParentCard;
