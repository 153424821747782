import {
  GETALLACCOUNTANT,
  GETALLCLIENT,
  ACCESSKEYRECEIVED,
  CREATENEWCLIENTFAILURE,
  SELECTEDCLIENTFORACCOUNTANT,
} from "src/modules/accountant/service/AccountantActionType";

const initialState = {
  // ALL Accountant
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  accountantList: [],
  clientList: [],

  accesKey: null,
  createClientFailure: {},

  selectedClient: {},
  isSelectedCLient: false,
};

export default function AccountantReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLACCOUNTANT:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        accountantList: action.payload.data,
      };

    case SELECTEDCLIENTFORACCOUNTANT:
      return {
        ...state,
        selectedClient: action.payload,
        isSelectedCLient: action.isSelectedClientForAccountant,
      };

    case GETALLCLIENT:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        clientList: action.payload.data,
      };

    case ACCESSKEYRECEIVED:
      return { ...state, accesKey: action.payload };

    case CREATENEWCLIENTFAILURE:
      return { ...state, createClientFailure: action.payload };

    default: {
      return state;
    }
  }
}
