import {
  IconPoint,
  IconDashboard,
  IconUserCircle,
  IconBuildingWarehouse,
  IconTicket,
  IconDiscount,
  IconPackage,
  IconClipboardText,
} from "@tabler/icons";

import { uniqueId } from "lodash";

const MenuitemsAdmin = [
  {
    navlabel: true,
    subheader: "Home",
  },

  {
    id: uniqueId(),
    title: "Dashboard",
    icon: IconDashboard,
    href: "/dashboard",
    children: [
      {
        id: uniqueId(),
        title: "Admin ",
        icon: IconPoint,
        href: "/admin/dashboard-shape",
        children: [
          {
            id: uniqueId(),
            title: "Coinvolgimento",
            icon: IconPoint,
            href: "/admin/dashboard/users-engagement",
          },

          {
            id: uniqueId(),
            title: "Finanziario",
            icon: IconPoint,
            href: "/admin/dashboard/financial",
          },
          {
            id: uniqueId(),
            title: "Supporto",
            icon: IconPoint,
            href: "/admin/dashboard/support",
          },
        ],
      },

      {
        id: uniqueId(),
        title: "Horeca ",
        icon: IconPoint,
        href: "/admin/dashboard/horeca",
      },
      {
        id: uniqueId(),
        title: "Accountants ",
        icon: IconPoint,
        href: "/admin/dashboard/accountant",
      },
    ],
  },
  {
    navlabel: true,
    subheader: "Gestione",
  },

  {
    id: uniqueId(),
    title: "Gestione Utenti",
    icon: IconUserCircle,
    href: "/admin/users/management/parent",
    chipColor: "secondary",

    children: [
      {
        id: uniqueId(),
        title: "Creazione Utente",
        icon: IconPoint,
        href: "/admin/users/management/createuser",
      },
      {
        id: uniqueId(),
        title: "Gestione Utenti ",
        icon: IconPoint,
        href: "/admin/users/management/edit",
      },

      {
        id: uniqueId(),
        title: "Messaggio Generale",
        icon: IconPoint,
        href: "/admin/users/generalcommunication",
      },
    ],
  },

  {
    id: uniqueId(),
    title: "Discount",
    icon: IconDiscount,
    href: "/discount",
    children: [
      {
        id: uniqueId(),
        title: "Creare ",
        icon: IconPoint,
        href: "/admin/discount/create",
      },
      {
        id: uniqueId(),
        title: "Elenco ",
        icon: IconPoint,
        href: "/admin/discount/list",
      },
    ],
  },

  {
    id: uniqueId(),
    title: "Plans",
    icon: IconClipboardText,
    href: "/admin/dashboard/plans/general",
    children: [
      {
        id: uniqueId(),
        title: "Creare",
        icon: IconPoint,
        href: "/admin/dashboard/plans/create",
      },
      {
        id: uniqueId(),
        title: "Horeca",
        icon: IconPoint,
        href: "/admin/dashboard/plans/horeca",
      },
      {
        id: uniqueId(),
        title: "Accountant",
        icon: IconPoint,
        href: "/admin/dashboard/plans/accountant",
      },
    ],
  },

  {
    id: uniqueId(),
    title: "Ricategorizzazione",
    icon: IconPackage,
    chipColor: "secondary",
    href: "/admin/product/categorization",

    // children: [
    //   {
    //     id: uniqueId(),
    //     title: "Level 1",
    //     icon: IconPoint,
    //     href: "/admin/category/list?level=1",
    //   },
    //   {
    //     id: uniqueId(),
    //     title: "Level 2",
    //     icon: IconPoint,
    //     href: "/admin/category/list?level=2",
    //   },
    //   {
    //     id: uniqueId(),
    //     title: "Level 3",
    //     icon: IconPoint,
    //     href: "/admin/category/list?level=3",
    //   },
    // ],
  },

  // {
  //   id: uniqueId(),
  //   title: "Products",
  //   icon: IconShoppingBag,
  //   chipColor: "secondary",
  //   href: "/productsManagement",
  // },
  {
    id: uniqueId(),
    title: "Fornitori",
    icon: IconBuildingWarehouse,
    href: "/admin/supplier/list",
  },

  {
    id: uniqueId(),
    title: "Assistenza",
    icon: IconTicket,
    href: "/tickets",
    children: [
      {
        id: uniqueId(),
        title: "Elenco richieste",
        icon: IconPoint,
        href: "/admin/ticketing/list",
      },
    ],
  },
];

export default MenuitemsAdmin;
