import axios from "axios";
import { environment } from "src/environement/environment";
import {
  GETCURRENTSUBSCRIPTION,
  GETCURRENTSUBSCRIPTIONERROR,
  GETFREEPLANSTATE,
  GETSUBSCRIPTIONHISTORY,
  GETPLANSTATE,
} from "./SubscriptionActionTypes";

export const getCurrentSubscription = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${environment.baseUrl}/subscription/myCurrent`
    );


    dispatch({
      type: GETCURRENTSUBSCRIPTION,
      payload: {
        data: response.data,
      },
    });
  } catch (e) {
    console.log("INVALID", e);
    if (e.response != null) {
      dispatch({
        type: GETCURRENTSUBSCRIPTIONERROR,
        payload: {
          data: e.response.data.message,
        },
      });
    }
  }
};

export const getFreeTrialState = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${environment.baseUrl}/subscription/freeTrial/state`
    );


    localStorage.setItem("freePlanExpiration", response.data.expired);

    dispatch({
      type: GETFREEPLANSTATE,
      payload: {
        data: response.data,
      },
    });
  } catch (e) {
    console.log("INVALID", e);
    if (e.response != null) {
      dispatch({
        type: GETFREEPLANSTATE,
        payload: {
          data: e.response.data.message,
        },
      });
    }
  }
};

export const getSubscriptionState = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${environment.baseUrl}/subscription/state`
    );


    dispatch({
      type: GETPLANSTATE,
      payload: {
        data: response.data,
      },
    });
    return response.data;
  } catch (e) {
    console.log("INVALID", e);
    if (e.response != null) {
      dispatch({
        type: GETPLANSTATE,
        payload: {
          data: e.response.data.message,
        },
      });
    }
  }
};

export const getSubscriptionHistory = (page, limit) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${environment.baseUrl}/subscription/allMySubscriptionHistory?page=${page}&limit=${limit}`
    );

    dispatch({
      type: GETSUBSCRIPTIONHISTORY,
      payload: {
        currentPage: response.data.currentPage,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
        data: response.data.data,
      },
    });
  } catch (e) {
    console.log("INVALID", e);
    if (e.response != null) {
      dispatch({
        type: GETSUBSCRIPTIONHISTORY,
        payload: {
          data: e.response.data.message,
        },
      });
    }
  }
};
