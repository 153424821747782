import {
  GETALLACCOUNTANT,
  GETALLCLIENT,
  GETALLINVOICECOSTUMER,
  GETINVOICEBYUUIDANDCOSTUMERUUID,
} from "src/modules/accountant/service/AccountantActionType";

const initialState = {
  // ALL Accountant
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  invoiceList: [],
  invoiceDetails: {},
};

export default function AccountantInvoiceReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLINVOICECOSTUMER:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        invoiceList: action.payload.data,
      };

    case GETINVOICEBYUUIDANDCOSTUMERUUID:
      return {
        ...state,
        invoiceDetails: action.payload.data,
      };

    default: {
      return state;
    }
  }
}
