import { GETALLSUPPLIERFORADMIN, GETINVOICESBYSUPPLIERBYADMIN, GETPRODUCTSBYSUPPLIERBYADMIN, GETSPENDINGITEMSBYSUPPLIERBYADMIN } from "src/modules/admin/supplier/service/AdminSupplierActionTypes";

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  supplierList: [],



    // INVOICES BY SUPPLIER
    currentPageInvoiceBySupplierForAdmin: 0,
    totalPagesInvoiceBySupplierForAdmin: 0,
    totalElementsInvoiceBySupplierForAdmin: 0,
    invoiceListBySupplierForAdmin: [],
  
    // PRODUCT BY SUPPLIER
    currentPageProductBySupplierForAdmin: 0,
    totalPagesProductBySupplierForAdmin: 0,
    totalElementsProductBySupplierForAdmin: 0,
    productListBySupplierForAdmin: [],
  
    // DATA FOR CHARTS
    itemSpendingBySupplierForAdmin: [],
    typeCharts: null,
};

export default function AdminSupplierReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLSUPPLIERFORADMIN:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        supplierList: action.payload.data,
      };

      case GETINVOICESBYSUPPLIERBYADMIN:
        return {
          ...state,
          currentPageInvoiceBySupplierForAdmin: action.payload.currentPage,
          totalPagesInvoiceBySupplierForAdmin: action.payload.totalPages,
          totalElementsInvoiceBySupplierForAdmin: action.payload.totalElements,
          invoiceListBySupplierForAdmin: action.payload.data,
        };
  
      case GETPRODUCTSBYSUPPLIERBYADMIN:
        return {
          ...state,
          currentPageProductBySupplierForAdmin: action.payload.currentPage,
          totalPagesProductBySupplierForAdmin: action.payload.totalPages,
          totalElementsProductBySupplierForAdmin: action.payload.totalElements,
          productListBySupplierForAdmin: action.payload.data,
        };
  
      case GETSPENDINGITEMSBYSUPPLIERBYADMIN:
        return {
          ...state,
          itemSpendingBySupplierForAdmin: action.payload.data,
          typeCharts: action.payload.typeCharts,
        };

    default: {
      return state;
    }
  }
}
