import {
    GETALLSUPPLIERBYCOSTUMER,
  } from "src/modules/accountant/service/AccountantActionType";
  
  const initialState = {
    // ALL Accountant
    currentPage: 0,
    totalPages: 0,
    totalElements: 0,
    supplierList: [],
  };
  
  export default function AccountantSupplierReducer(state = initialState, action) {
    switch (action.type) {
      case GETALLSUPPLIERBYCOSTUMER:
        return {
          ...state,
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
          supplierList: action.payload.data,
        };
  
      default: {
        return state;
      }
    }
  }
  