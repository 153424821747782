import { GETALLUSERS } from "src/modules/admin/account/service/AccountActionType";

const initialState = {


  // ALL USERS
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  userList: [],
};

export default function AdminAccountReducer(state = initialState, action) {
  switch (action.type) {
    case GETALLUSERS:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
        userList: action.payload.data,
      };
    default: {
      return state;
    }
  }
}
