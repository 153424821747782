import {
  LOGIN_REQUEST,
  REFRESHTOKEN,
  LOGOUT_REQUEST,
  FAILURE,
  SIGNUP,
  FORGOTPASSWORD,
  AUTHSUCCESS,
  RESETPASSWORD,
  SIGNUPERROR,
  SUCCESCONFIRMATIONMAIL,
  FAILURECONFIRMATIONMAIL,
  CONFIRMATIONPROCESS,
} from "./AuthTypes";
import { environment } from "src/environement/environment";
import axios from "axios";
import { clearCookies } from "src/utils/cookiesTools";
import swal from "sweetalert";
import { formatToCapitalize } from "src/utils/formatRowValue";

export const authenticationUser = (payload, navigate) => async (dispatch) => {
  try {
    clearCookies();


    delete axios.defaults.headers.common["Authorization"];
    const response = await axios.post(
      `${environment.baseUrl}/userRegistration/login`,
      payload
    );


    const { cacheControl, contentLength, contentType, expires, pragma } =
      response.headers;

    const headers = {
      cacheControl,
      contentLength,
      contentType,
      expires,
      pragma,
    };

    dispatch({
      type: LOGIN_REQUEST,
      payload: { data: response.data, headers },
    });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.refreshToken}`;

    if (response.status === 200) {
      const roles = response.data.roles;

      // IF HAS CLIENT ROLE
      if (roles.includes("ROLE_HORECA")) {
        navigate("/horeca/dashboard");
        dispatch({
          type: AUTHSUCCESS,
          payload: { data: true, roles: roles },
        });
      }
      // IF HAS ADMIN ROLE
      else if (roles.includes("ROLE_ADMIN")) {
        navigate("/admin/dashboard");
        dispatch({
          type: AUTHSUCCESS,
          payload: { data: true, roles: roles },
        });
      }
      // IF HAS ACCOUNTANT ROLE
      else if (roles.includes("ROLE_ACCOUNTANT")) {
        navigate("/accountant/dashboard");
        dispatch({
          type: AUTHSUCCESS,
          payload: { data: true, roles: roles },
        });
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");

        navigate("/auth/login", { replace: true });
      }
    }

    return false;

  } catch (e) {
    if (e.response && e.response.data && e.response.status === 451) {
      dispatch({
        type: FAILURE,
        payload: e.response.data.message,
        errorStatus: 451,
      });
    } else if (e.response && e.response.data) {
      dispatch({ type: FAILURE, payload: e.response.data.message });
    }


    return false;
    
  }
};

export const refreshUser = () => async (dispatch) => {
  try {
    const response = await axios.post(
      `${environment.baseUrl}/userRegistration/refresh`
    );

    if (response.status === 201) {
      dispatch({
        type: REFRESHTOKEN,
        payload: { data: true, roles: response.data.roles },
      });
    }
  } catch (e) {
    dispatch({ type: FAILURE });

  }
};

export const signUpUser = (payload, navigate) => async (dispatch) => {
  try {
    clearCookies();

    delete axios.defaults.headers.common["Authorization"];

    const response = await axios.post(
      `${environment.baseUrl}/userRegistration/register`,
      payload
    );


    dispatch({
      type: SIGNUP,
      payload: { data: false },
    });

    if (response.status === 201) {
      navigate("/auth/confirmation", {
        state: { from: { pathname: "/auth/register" } },
      });
    }

    return false;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.status === 500) {
      dispatch({ type: SIGNUPERROR, payload: e.response.data.message });

      return false;
    }
    return false;
  }
};

export const logOutAttempt = () => async (dispatch) => {
  dispatch({ type: LOGOUT_REQUEST });
};

export const forgotPasswordAttempt =
  (payload, navigate) => async (dispatch) => {
    try {
      clearCookies();

      const response = await axios.post(
        `${environment.baseUrl}/userRegistration/resetPasswordMailing?email=${payload.email}`
      );

      dispatch({
        type: FORGOTPASSWORD,
      });

      if (response.status === 201) {
        localStorage.setItem("previousPath", "/auth/forgot-password");
        navigate("/auth/forgot-password/confirmation", { replace: true });
      }

      return false;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 500) {
        dispatch({
          type: SIGNUP,
          payload: { data: true },
        });
        return true;
      }
      return false;
    }
  };

export const resetPasswordAttempt = (payload, navigate) => async (dispatch) => {
  try {
    clearCookies();
    delete axios.defaults.headers.common["Authorization"];


    const response = await axios.post(
      `${environment.baseUrl}/userRegistration/resetPassword?token=${payload.token}&newPassword=${payload.newPassword}`,
      payload
    );
    dispatch({
      type: RESETPASSWORD,
      payload: { data: true },
    });
    swal({
      title: "Success",
      text: response.data.message,
      icon: "success",
      button: "OK",
    });
    if (response.status === 201) {
      navigate("/auth/login");
    }
    return false;
  } catch (e) {
    if (e.response != null) {
      dispatch({
        type: RESETPASSWORD,
        payload: { data: false },
      });
      swal({
        title: "Richiesta non riuscita",
        text: ` ${formatToCapitalize(e.response.data.message)} `,
        icon: "error", // Change icon to 'error' for indicating an error
        button: "OK",
        dangerMode: true, // Set dangerMode to true to display the message with a red color
      });
    }
  }
};

export const sendConfirmationMail = (email, navigate) => async (dispatch) => {
  try {
    clearCookies();

    delete axios.defaults.headers.common["Authorization"];

    const response = await axios.post(
      `${environment.baseUrl}/userRegistration/sendConfirmationMail?email=${email}`
    );

    dispatch({
      type: SUCCESCONFIRMATIONMAIL,
    });

    if (response.status === 201) {
      navigate("/auth/confirmation", {
        state: { from: { pathname: "/auth/confirmationMailing" } },
      });
    }

    return false;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.status === 500) {
      dispatch({
        type: FAILURECONFIRMATIONMAIL,
        payload: e.response.data.message,
      });

      return true;
    }
    return false;
  }
};

export const confirmAccountProcess = (token, navigate) => async (dispatch) => {
  try {
    clearCookies();

    delete axios.defaults.headers.common["Authorization"];
    const response = await axios.post(
      `${environment.baseUrl}/userRegistration/confirm?token=${token}`
    );

    const { cacheControl, contentLength, contentType, expires, pragma } =
      response.headers;

    const headers = {
      cacheControl,
      contentLength,
      contentType,
      expires,
      pragma,
    };

    dispatch({
      type: CONFIRMATIONPROCESS,
      payload: { data: response.data, headers },
    });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.refreshToken}`;

    if (response.status === 200) {
      const roles = response.data.roles;

      // IF HAS CLIENT ROLE
      if (roles.includes("ROLE_HORECA")) {
        navigate("/horeca/dashboard");
        dispatch({
          type: AUTHSUCCESS,
          payload: { data: true, roles: roles },
        });
      }
      // IF HAS ADMIN ROLE
      else if (roles.includes("ROLE_ADMIN")) {
        navigate("/admin/dashboard");
        dispatch({
          type: AUTHSUCCESS,
          payload: { data: true, roles: roles },
        });
      }
      // IF HAS ACCOUNTANT ROLE
      else if (roles.includes("ROLE_ACCOUNTANT")) {
        navigate("/accountant/dashboard");
        dispatch({
          type: AUTHSUCCESS,
          payload: { data: true, roles: roles },
        });
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        console.error("Error refreshing token:");

        navigate("/auth/login", { replace: true });
      }
    }
  } catch (e) {
    if (e.response && e.response.data) {
      swal({
        title: "Richiesta non riuscita",
        text: ` ${formatToCapitalize(e.response.data.message)} `,
        icon: "error", // Change icon to 'error' for indicating an error
        button: "OK",
        dangerMode: true, // Set dangerMode to true to display the message with a red color
      });
      navigate("/auth/login", { replace: true });
    }

    console.log(e, "INVALID");
  }
};
