import axios from "axios";


const setTokenAuth = (refresh_token) => {
    if (refresh_token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;

    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };
  export default setTokenAuth;
