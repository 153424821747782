import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  FAILURE,
  SUCCES,
  REFRESHTOKEN,
  SIGNUP,
  SIGNUPERROR,
  AUTHSUCCESS,
  FAILURECONFIRMATIONMAIL,
  CONFIRMATIONPROCESS,
} from "src/modules/account/service/AuthTypes";
import { clearCookies } from "src/utils/cookiesTools";

const initialState = {
  LoginAndPassword: {},
  isAuthenticated: null,
  isUserExist: null,
  loginFailure: null,
  rolesUser: [],
  failureConfirmation: null,
  errorStatus: null,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      localStorage.setItem("access_token", action.payload.data.accessToken);
      localStorage.setItem("refresh_token", action.payload.data.refreshToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("rolesUser", action.payload.roles);

      return {
        ...state,
        isAuthenticated: action.payload.data,
        rolesUser: action.payload.roles,
      };

    case CONFIRMATIONPROCESS:
      localStorage.setItem("access_token", action.payload.data.accessToken);
      localStorage.setItem("refresh_token", action.payload.data.refreshToken);
      localStorage.setItem("isAuthenticated", action.payload.data);
      localStorage.setItem("rolesUser", action.payload.roles);
      return {
        ...state,
        isAuthenticated: action.payload.data,
        rolesUser: action.payload.roles,
      };

    case LOGOUT_REQUEST:
      clearCookies();

      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("rolesUser");

      // Refresh the page to ensure all user data is cleared from the current session
      return { isAuthenticated: false, rolesUser: [] };
    case AUTHSUCCESS:
      localStorage.setItem("isAuthenticated", action.payload.data);
      localStorage.setItem("rolesUser", action.payload.roles);
      return {
        ...state,
        isAuthenticated: action.payload.data,
        rolesUser: action.payload.roles,
      };

    case FAILURECONFIRMATIONMAIL:
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("rolesUser");
      return {
        ...state,
        isAuthenticated: false,
        failureConfirmation: action.payload,
      };

    case SIGNUP:
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("rolesUser");
      return {
        ...state,
        isAuthenticated: false,
        isUserExist: action.payload.data,
      };
    case FAILURE:
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("rolesUser");
      clearCookies();

      return {
        ...state,
        isAuthenticated: false,
        loginFailure: action.payload,
        errorStatus: action.errorStatus,
        rolesUser: [],
      };

    case SIGNUPERROR:
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("rolesUser");

      clearCookies();

      return {
        ...state,
        isAuthenticated: false,
        registerFailure: action.payload,
        rolesUser: [],
      };

    case REFRESHTOKEN:
      return {
        ...state,
        rolesUser: action.payload.roles,
        isAuthenticated: action.payload.data,
      };

    default: {
      return state;
    }
  }
}
