import axios from "axios";
import { environment } from "src/environement/environment";
import { GETALLPLANSADMIN, UPDATEPLANINFO } from "./PlansAdminActionTypes";
import swal from "sweetalert";
import { formatToCapitalize } from "src/utils/formatRowValue";
import { filterPlansByYearOrMonth } from "src/utils/PlansTools";

export const getAllPlansForAdmin =
  (yearly, typeAccount) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${environment.baseUrl}/public/subscriptionPlans/all?type=${typeAccount}`
      );

      const filtredPlans = filterPlansByYearOrMonth(response.data, yearly);
      dispatch({
        type: GETALLPLANSADMIN,
        payload: {
          data: filtredPlans,
        },
      });
    } catch (e) {
      console.log("INVALID", e);
      if (e.response != null) {
        swal({
          title: "Richiesta non riuscita",
          text: ` ${formatToCapitalize(e.response.data.message)} `,
          icon: "error", // Change icon to 'error' for indicating an error
          button: "OK",
          dangerMode: true, // Set dangerMode to true to display the message with a red color
        });
      }
    }
  };

export const updatePlan = (payload, uuid) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${environment.baseUrl}/subscription-plans/${uuid}`,
      payload
    );


    swal({
      title: "Success",
      text: "Plan Updated Succesfully",
      icon: "success",
      button: "OK",
    });

    return false;
  } catch (e) {
    console.log("INVALID", e);

    if (e.response) {
      swal({
        title: "Richiesta non riuscita",
        text: ` ${formatToCapitalize(e.response.data.message)} `,
        icon: "error", // Change icon to 'error' for indicating an error
        button: "OK",
        dangerMode: true, // Set dangerMode to true to display the message with a red color
      });
    } // <-- Add this closing curly brace
    return false;
  }
};

export const createNewPlan = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${environment.baseUrl}/subscription-plans`,
      payload
    );


    swal({
      title: "Success",
      text: "Plan Created Succesfully",
      icon: "success",
      button: "OK",
    });

    const objectToReturn = {
      loadingButton: false,
      clearInput: true,
    };

    return objectToReturn;
  } catch (e) {
    console.log("INVALID", e);

    if (e.response) {
      swal({
        title: "Richiesta non riuscita",
        text: ` ${formatToCapitalize(e.response.data.message)} `,
        icon: "error", // Change icon to 'error' for indicating an error
        button: "OK",
        dangerMode: true, // Set dangerMode to true to display the message with a red color
      });
    } // <-- Add this closing curly brace
    const objectToReturn = {
      loadingButton: false,
      clearInput: false,
    };
    return objectToReturn;
  }
};
