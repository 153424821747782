import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useSelector, connect, useDispatch } from "react-redux";
import { IconPower } from "@tabler/icons";
import { Link, useNavigate } from "react-router-dom";

import { logOutAttempt } from "src/modules/account/service/AuthAction";
import { RESETLASTSYSTEMOPERATION } from "src/modules/systemOperation/service/SystemOperationActionTypes";
import { disconnectWebSocket } from "src/modules/systemOperation/service/SystemOperationService";
const Profile = ({ logOutAttempt, disconnectWebSocket }) => {
  const customizer = useSelector((state) => state.customizer);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const hideMenu = lgUp
    ? customizer.isCollapse && !customizer.isSidebarHover
    : "";

  const [userAuthentified, setUserAuthentified] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function decodeJWT(token) {
    if (token) {
      const getToken = token.split(".");

      return JSON.parse(window.atob(getToken[1]));
    }
  }

  useEffect(() => {
    if (localStorage.getItem("refresh_token")) {
      setUserAuthentified(decodeJWT(localStorage.getItem("refresh_token")));
    }
  }, []);

  const logoutAttempt = async () => {
    await disconnectWebSocket();
    dispatch({ type: RESETLASTSYSTEMOPERATION });

    await logOutAttempt();
    navigate("/auth/login");
  };

  return (
    <Box
      display={"flex"}
      alignItems="center"
      gap={2}
      sx={{ m: 2, p: 1.9, bgcolor: "#EDE0D4" }}
    >
      {!hideMenu ? (
        <>
          {userAuthentified && (
            <>
              <Avatar
                alt={userAuthentified.company_name}
                sx={{ bgcolor: "#84665b", fontWeight: 100 }}
              >
                {userAuthentified.company_name.charAt(0).toUpperCase()}
              </Avatar>

              <Box>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "12px", color: "#000000" }}
                >
                  {userAuthentified.company_name}
                </Typography>
              </Box>

              <Box sx={{ ml: "auto" }}>
                <Tooltip title="Logout" placement="top">
                  <IconButton
                    onClick={logoutAttempt}
                    color="primary"
                    aria-label="logout"
                    size="small"
                  >
                    <IconPower size="20" />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

export default connect(mapStateToProps, { logOutAttempt, disconnectWebSocket })(
  Profile
);
